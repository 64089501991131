import { QueryHookOptions } from '@apollo/client/index.js'
import { useSearchParams } from '@remix-run/react'
import {
  CurrentUserQuery,
  CurrentUserQueryVariables,
  useCurrentUserQuery,
} from '../queries/user/currentUser.query.gen'
import { Profile, ProfileType } from '../types.gen'
import { DEFAULT_CITY } from '../../components/forms/SelectCity/SelectCity'
import { cities } from './cities'

export default function useCurrentUser(
  options?: QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>,
) {
  let result = useCurrentUserQuery({
    fetchPolicy: 'cache-first',
    ...options,
  })
  // const citiesResult = useGetCitiesQuery({
  //   fetchPolicy: 'cache-first',
  // })

  let user = result.data?.currentUser
  let [searchParams] = useSearchParams()

  let city = user?.city || DEFAULT_CITY

  if (!user) {
    let cityName = searchParams.get('city')

    if (cityName) {
      // let cities = citiesResult.data?.getCities ?? []

      let foundCity = cities.find((c) => c.name === cityName)

      if (foundCity) {
        city = foundCity
      }
    }
  }

  return {
    ...result,
    isAuthenticated: Boolean(user),
    id: user?.id,
    user,
    selectedProfile: user?.selectedProfile,
    city,
  }
}

export function useFindProfileBySlug(slug: string) {
  let { user } = useCurrentUser()

  return user?.profiles.find((profile) => profile.slug === slug)
}

export function profileTypeNameToProfileType(
  typeName: Profile['__typename'],
): ProfileType {
  switch (typeName) {
    case 'Artist':
      return ProfileType.Artist

    case 'Venue':
      return ProfileType.Venue

    case 'Promoter':
      return ProfileType.Promoter

    default:
      throw new Error('Illegal profile type in ticket split')
  }
}

export function profileTypeToProfileTypeName(
  profileType: ProfileType,
): Profile['__typename'] {
  switch (profileType) {
    case ProfileType.Artist:
      return 'Artist'

    case ProfileType.Venue:
      return 'Venue'

    case ProfileType.Promoter:
      return 'Promoter'

    default:
      throw new Error('Illegal profile type in ticket split')
  }
}
