import { City } from '../types.gen'

// Storing these here to speed up loading times
export let cities: City[] = [
  {
    __typename: 'City',
    id: 1,
    name: 'Glasgow',
  },
  {
    __typename: 'City',
    id: 2,
    name: 'Edinburgh',
  },
  {
    __typename: 'City',
    id: 3,
    name: 'London',
  },
  {
    __typename: 'City',
    id: 4,
    name: 'Manchester',
  },
  {
    __typename: 'City',
    id: 5,
    name: 'Liverpool',
  },
  {
    __typename: 'City',
    id: 6,
    name: 'Brighton',
  },
  {
    __typename: 'City',
    id: 7,
    name: 'Linlithgow',
  },
  {
    __typename: 'City',
    id: 8,
    name: 'Troon',
  },
  {
    __typename: 'City',
    id: 9,
    name: 'Birmingham',
  },
  {
    __typename: 'City',
    id: 10,
    name: 'Leeds',
  },
  {
    __typename: 'City',
    id: 11,
    name: 'Newcastle',
  },
  {
    __typename: 'City',
    id: 12,
    name: 'Birstall',
  },
  {
    __typename: 'City',
    id: 13,
    name: 'Portsmouth',
  },
  {
    __typename: 'City',
    id: 14,
    name: 'Southampton',
  },
  {
    __typename: 'City',
    id: 15,
    name: 'Nottingham',
  },
  {
    __typename: 'City',
    id: 16,
    name: 'Bristol',
  },
  {
    __typename: 'City',
    id: 17,
    name: 'Sheffield',
  },
  {
    __typename: 'City',
    id: 18,
    name: 'Kingston upon Hull',
  },
  {
    __typename: 'City',
    id: 19,
    name: 'Leicester',
  },
  {
    __typename: 'City',
    id: 20,
    name: 'Caerdydd',
  },
  {
    __typename: 'City',
    id: 21,
    name: 'Stoke-on-Trent',
  },
  {
    __typename: 'City',
    id: 22,
    name: 'Coventry',
  },
  {
    __typename: 'City',
    id: 23,
    name: 'Reading',
  },
  {
    __typename: 'City',
    id: 24,
    name: 'Belfast',
  },
  {
    __typename: 'City',
    id: 25,
    name: 'Derby',
  },
  {
    __typename: 'City',
    id: 26,
    name: 'Plymouth',
  },
  {
    __typename: 'City',
    id: 27,
    name: 'Wolverhampton',
  },
  {
    __typename: 'City',
    id: 28,
    name: 'Abertawe',
  },
  {
    __typename: 'City',
    id: 29,
    name: 'Milton Keynes',
  },
  {
    __typename: 'City',
    id: 30,
    name: 'Aberdeen',
  },
  {
    __typename: 'City',
    id: 31,
    name: 'Norwich',
  },
  {
    __typename: 'City',
    id: 32,
    name: 'Luton',
  },
  {
    __typename: 'City',
    id: 33,
    name: 'Islington',
  },
  {
    __typename: 'City',
    id: 34,
    name: 'Swindon',
  },
  {
    __typename: 'City',
    id: 35,
    name: 'Croydon',
  },
  {
    __typename: 'City',
    id: 36,
    name: 'Basildon',
  },
  {
    __typename: 'City',
    id: 37,
    name: 'Bournemouth',
  },
  {
    __typename: 'City',
    id: 38,
    name: 'Worthing',
  },
  {
    __typename: 'City',
    id: 39,
    name: 'Ipswich',
  },
  {
    __typename: 'City',
    id: 40,
    name: 'Middlesbrough',
  },
  {
    __typename: 'City',
    id: 41,
    name: 'Sunderland',
  },
  {
    __typename: 'City',
    id: 42,
    name: 'Ilford',
  },
  {
    __typename: 'City',
    id: 43,
    name: 'Warrington',
  },
  {
    __typename: 'City',
    id: 44,
    name: 'Slough',
  },
  {
    __typename: 'City',
    id: 45,
    name: 'Huddersfield',
  },
  {
    __typename: 'City',
    id: 46,
    name: 'Oxford',
  },
  {
    __typename: 'City',
    id: 47,
    name: 'York',
  },
  {
    __typename: 'City',
    id: 48,
    name: 'Poole',
  },
  {
    __typename: 'City',
    id: 49,
    name: 'Harrow',
  },
  {
    __typename: 'City',
    id: 50,
    name: 'Dundee',
  },
  {
    __typename: 'City',
    id: 51,
    name: 'Saint Albans',
  },
  {
    __typename: 'City',
    id: 52,
    name: 'Telford',
  },
  {
    __typename: 'City',
    id: 53,
    name: 'Blackpool',
  },
  {
    __typename: 'City',
    id: 54,
    name: 'Sale',
  },
  {
    __typename: 'City',
    id: 55,
    name: 'Enfield',
  },
  {
    __typename: 'City',
    id: 56,
    name: 'Tottenham',
  },
  {
    __typename: 'City',
    id: 57,
    name: 'Bolton',
  },
  {
    __typename: 'City',
    id: 58,
    name: 'Newport',
  },
  {
    __typename: 'City',
    id: 59,
    name: 'High Wycombe',
  },
  {
    __typename: 'City',
    id: 60,
    name: 'Exeter',
  },
  {
    __typename: 'City',
    id: 61,
    name: 'Solihull',
  },
  {
    __typename: 'City',
    id: 62,
    name: 'Romford',
  },
  {
    __typename: 'City',
    id: 63,
    name: 'Preston',
  },
  {
    __typename: 'City',
    id: 64,
    name: 'Gateshead',
  },
  {
    __typename: 'City',
    id: 65,
    name: 'Blackburn',
  },
  {
    __typename: 'City',
    id: 66,
    name: 'Cheltenham',
  },
  {
    __typename: 'City',
    id: 67,
    name: 'Basingstoke',
  },
  {
    __typename: 'City',
    id: 68,
    name: 'Maidstone',
  },
  {
    __typename: 'City',
    id: 69,
    name: 'Colchester',
  },
  {
    __typename: 'City',
    id: 70,
    name: 'Chelmsford',
  },
  {
    __typename: 'City',
    id: 71,
    name: 'Wythenshawe',
  },
  {
    __typename: 'City',
    id: 72,
    name: 'Doncaster',
  },
  {
    __typename: 'City',
    id: 73,
    name: 'Rotherham',
  },
  {
    __typename: 'City',
    id: 74,
    name: 'Walthamstow',
  },
  {
    __typename: 'City',
    id: 75,
    name: 'Rochdale',
  },
  {
    __typename: 'City',
    id: 76,
    name: 'Bedford',
  },
  {
    __typename: 'City',
    id: 77,
    name: 'Crawley',
  },
  {
    __typename: 'City',
    id: 78,
    name: 'Mansfield',
  },
  {
    __typename: 'City',
    id: 79,
    name: 'Dagenham',
  },
  {
    __typename: 'City',
    id: 80,
    name: 'Stockport',
  },
  {
    __typename: 'City',
    id: 81,
    name: 'Darlington',
  },
  {
    __typename: 'City',
    id: 82,
    name: 'Fyfield',
  },
  {
    __typename: 'City',
    id: 83,
    name: 'Gillingham',
  },
  {
    __typename: 'City',
    id: 84,
    name: 'Salford',
  },
  {
    __typename: 'City',
    id: 85,
    name: 'Eastbourne',
  },
  {
    __typename: 'City',
    id: 86,
    name: 'Wigan',
  },
  {
    __typename: 'City',
    id: 87,
    name: 'Hounslow',
  },
  {
    __typename: 'City',
    id: 88,
    name: 'Wembley',
  },
  {
    __typename: 'City',
    id: 89,
    name: 'Saint Helens',
  },
  {
    __typename: 'City',
    id: 90,
    name: 'Worcester',
  },
  {
    __typename: 'City',
    id: 91,
    name: 'Wakefield',
  },
  {
    __typename: 'City',
    id: 92,
    name: 'Lincoln',
  },
  {
    __typename: 'City',
    id: 93,
    name: 'Hemel Hempstead',
  },
  {
    __typename: 'City',
    id: 94,
    name: 'Watford',
  },
  {
    __typename: 'City',
    id: 95,
    name: 'Oldham',
  },
  {
    __typename: 'City',
    id: 96,
    name: 'Sutton Coldfield',
  },
  {
    __typename: 'City',
    id: 97,
    name: 'Kettering',
  },
]
