import React, { useId, useState } from 'react'
import { css, useTheme } from '@emotion/react'
import { Props as ReactSelectProps } from 'react-select'

import Select from '../Select'
import { Label } from '../Input/Label'
import { useCurrentUser } from '../../../graphql/hooks'
import { mTheme } from '../../../style/themes'
import { useUpdateUserProfileMutation } from '../../../graphql/mutations/updateUserProfile.mut.gen'
import { useGetCitiesQuery } from '../../../graphql/queries/city/getCities.query.gen'
import { City } from '../../../graphql/types.gen'
import { useSearchParams } from '@remix-run/react'
import currentUserQuery from '../../../graphql/queries/user/currentUser.query'

const styles: Styles = {
  cityInput: css({
    width: '100%',
  }),
  dropdownIndicator: css({
    color: mTheme.fontColor.plain.tertiary,
    paddingTop: mTheme.size.XXS,
    paddingRight: mTheme.size.XS,
    fontSize: mTheme.fontSize.S,
  }),

  cityOption: css({
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: mTheme.fontColor.plain.primary,
  }),
}

export interface CityOption {
  value: number
  label: string
}

export const DEFAULT_CITY: City = {
  id: 1,
  name: 'Glasgow',
}

type SelectCityProps = ReactSelectProps<CityOption, false>

const generateOptions = (cities: City[] = []): CityOption[] => {
  return cities.map((city) => ({
    value: city.id,
    label: city.name,
  }))
}

// const optionToCity = (option: CityOption): City => ({
//   id: option.value,
//   name: option.label,
// })

const SelectCity: React.FC<SelectCityProps> = ({ value, ...props }) => {
  const { data, loading } = useGetCitiesQuery({
    fetchPolicy: 'cache-first',
  })
  const cities = data?.getCities

  const options = loading
    ? generateOptions([DEFAULT_CITY])
    : generateOptions(cities)

  let id = useId()

  return (
    <Select
      isLoading={loading || props.isLoading}
      placeholder="City"
      value={value}
      options={options}
      id={id}
      inputId={id}
      instanceId={id}
      noOptionsMessage={(state) => {
        if (state.inputValue) {
          return `No cities with "${state.inputValue}" found. Want Yupty for your city? Let us know support@yupty.live`
        }

        return null
      }}
      {...props}
    />
  )
}

export function SelectCityMutating(props: SelectCityProps) {
  const { user, city } = useCurrentUser()
  const [, setSearchParams] = useSearchParams()
  const [initialValue] = generateOptions([city ?? DEFAULT_CITY])
  const [newValue, setNewValue] = useState<CityOption>()
  const [updateUserProfile] = useUpdateUserProfileMutation()
  const [loading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <SelectCity
      {...props}
      isDisabled={loading}
      isLoading={loading}
      defaultValue={initialValue}
      onMenuOpen={() => setIsOpen(true)}
      onMenuClose={() => setIsOpen(false)}
      value={newValue || initialValue}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: () => {
          if (loading) {
            return null
          }

          return (
            <div css={styles.dropdownIndicator}>
              {isOpen ? (
                <i className="fas fa-redo" />
              ) : (
                <i className="fas fa-chevron-down" />
              )}
            </div>
          )
        },
      }}
      onChange={(option) => {
        if (!option) {
          throw new Error('Empty city option.')
        }

        setLoading(true)
        setNewValue(option)
        setSearchParams({ city: option.label.toString() }, { replace: true })

        if (user) {
          updateUserProfile({
            variables: {
              profileOptions: {
                id: user.id,
                cityId: option.value,
              },
            },
            update: (cache) => {
              cache.writeQuery({
                query: currentUserQuery,
                data: {
                  currentUser: {
                    ...user,
                    city: {
                      id: option.value,
                      name: option.label,
                    },
                  },
                },
              })
            },
          })
        } else {
          // window.location.reload()
          setLoading(false)
        }
      }}
    />
  )
}

export function SelectCityMutatingCompact(props: SelectCityProps) {
  const theme = useTheme()

  return (
    <SelectCityMutating
      styles={{
        singleValue: (style) => ({
          ...style,
          padding: `${theme.size.XS} 0`,
        }),
        indicatorSeparator: () => ({ display: 'none' }),
        dropdownIndicator: (style) => ({
          ...style,
          color: theme.borderColor.secondary,
        }),
        control: (style) => {
          return {
            ...style,
            width: 'fit-content',
            minWidth: 100,
            borderRadius: theme.radius.primary,
            borderWidth: '2px',
            borderColor: theme.borderColor.tertiary,
          }
        },
        container: (style) => ({
          ...style,
          fontSize: theme.fontSize.S,
        }),
      }}
      {...props}
    />
  )
}

export function SelectCityFormInput(props: SelectCityProps) {
  return (
    <div css={styles.cityInput}>
      <Label text="City" iconClassName="fas fa-map-marker-alt" />
      <SelectCity {...props} />
    </div>
  )
}
